import React from 'react'
import styled from 'styled-components'

import Content from '../Content'
import Title from '../Title'
import Highlight from '../Highlight'
import ArrowRight from '../Icons/ArrowRight'

const Wrapper = styled.div`
  background: #f5f5f5;
  padding: 6rem 0 3rem 0;

  @media (min-width: 1024px) {
    padding: 9rem 0 6rem 0;
  }
`

const Heading = styled.div`
  max-width: 1110px;
  margin-bottom: 48px;
`

const Columns = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const Column = styled.div`
  @media (max-width: 1023px) {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  @media (min-width: 1024px) {
    width: 50%;
    padding-right: 100px;
  }
`

const Intro = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0 0 0.5em 0;
`

const Text = styled.div`
  font-size: 21px;
`

const Button = styled.a`
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  svg {
    margin-left: 8px;
    height: 20px;
    width: auto;
    position: relative;
    top: 1px;
    transition: transform 0.25s ease;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`

const Meta = styled.div`
  max-width: 500px;
`

const Media = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 1rem;
`

export const Img = styled.img`
  display: block;
  height: auto;
  width: 200vw;

  @media (min-width: 800px) {
    width: 100vw;
  }

  @media (min-width: 1560px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
`

export const Proflow = () => (
  <Wrapper>
    <Content>
      <Heading>
        <Intro>Digitale Transformation</Intro>
        <Title>Digitalisieren Sie Ihre Arbeit und Prozesse.</Title>
      </Heading>
      <Columns>
        <Column>
          <Text>
            ProFlow ist eine Software mit Methode, um Arbeit sichtbar zu machen.
            Die Visualisierung der Arbeit verbessert den Arbeitsfluss und die
            Transparenz erheblich. Damit werden Probleme und Engpässe frühzeitig
            erkannt und alle im Team wissen, was, warum und wie erledigt werden
            soll. Durch die Digitalisierung der Prozesse sowie die
            Automatisierung wiederholender Aufgaben bietet die Software den
            idealen Start in die Arbeit 4.0.
          </Text>
        </Column>
        <Column>
          <Meta>
            <Text>
              Mit Software und Methode zu effektiven und kundenorientierten
              Prozessen.
            </Text>
            <br />
            <Button href="https://www.proflow.li">
              <Highlight>Mehr erfahren</Highlight> <ArrowRight />
            </Button>
          </Meta>
        </Column>
      </Columns>
      <Media>
        <a href="https://www.proflow.li">
          <Img src="/proflow_web_v2.jpg" alt="ProFlow - Erreichen Sie mehr mit weniger Aufwand!" />
        </a>
      </Media>
    </Content>
  </Wrapper>
)
