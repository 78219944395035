import styled, { css } from 'styled-components'

const Highlight = styled.span.attrs(({ light }) => ({
  light: light || false,
}))`
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    bottom: 0.05em;
    left: 0;
    right: 0;
    height: 0.25em;
    background: #feef9e;
    ${props =>
      props.light &&
      css`
        background: red;
      `}
    z-index: -1;
  }
`

export default Highlight
