import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import shuffle from 'lodash/shuffle'

import Content from '../Content'

const Heading = styled.h1`
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 2.5em;

  @media (min-width: 860px) {
    margin-bottom: 4em;
  }

  strong {
    font-size: 84px;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 1rem 0;

    @media (max-width: 860px) {
      font-size: 60px;
    }

    @media (max-width: 480px) {
      font-size: 54px;
    }
  }
`

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -12px -24px;

  /*
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 48px 96px;
  justify-content: center;
  align-content: center;

  @media (max-width: 1024px) {
    grid-gap: 64px;
  }

  @media (max-width: 680px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  @media (max-width: 420px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 0.5fr));
  }
  */
`

const LogosItem = styled.div`
  padding: 18px 24px;
  flex: auto 0 0;
  flex-basis: 50%;

  @media (min-width: 540px) {
    flex-basis: 33.333333%;
  }

  @media (min-width: 840px) {
    padding: 24px;
    flex-basis: 25%;
  }

  @media (min-width: 1024px) {
    flex-basis: 20%;
  }

  @media (min-width: 1440px) {
    flex-basis: 16.666666%;

    &.optional-1 {
      display: none;
    }
  }
`

const Logo = styled.div`
  background: transparent;
  position: relative;
  max-width: 140px;
  margin: auto;

  &::before {
    // for apsect ratio
    content: '';
    display: block;
    padding-bottom: 40%;

    @media (max-width: 680px) {
      padding-bottom: 33.333333%;
    }
  }
`

const Image = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  &.is-png {
    width: auto;
    height: auto;
  }
`

const Wrapper = styled.div`
  margin-bottom: 8rem;

  @media (max-width: 680px) {
    margin-bottom: 6rem;
  }
`

const logosData = [
  { filename: 'architektur-atelier.svg' },
  { filename: 'auhof.svg' },
  { filename: 'awi.svg' },
  { filename: 'businesstag.svg' },
  { filename: 'cll.svg' },
  { filename: 'ekey.svg' },
  { filename: 'etimark.svg' },
  { filename: 'franzhasler.svg' },
  { filename: 'freygner.svg' },
  { filename: 'gasserpartner.svg' },
  { filename: 'gebr-beck.svg' },
  { filename: 'golfen-mit-herz-logo.svg' },
  { filename: 'herzogloibner.svg' },
  { filename: 'itw.svg' },
  { filename: 'lapreva.svg' },
  { filename: 'lebenswertesliechtenstein.svg' },
  { filename: 'leoneming.svg' },
  { filename: 'life.svg' },
  { filename: 'lihga.svg' },
  { filename: 'marquart.svg' },
  { filename: 'nahrin.svg' },
  { filename: 'next-step.svg' },
  { filename: 'oberhuberjenal.svg' },
  { filename: 'post.svg' },
  { filename: 'rheintalwein.svg' },
  { filename: 'sos-kinderdorf.svg' },
  { filename: 'tak-2.svg' },
  { filename: 'tuerex.svg' },
  { filename: 'tws.svg' },
  { filename: 'uni-liechtenstein.svg' },
  { filename: 'vaduz-2.svg' },
  { filename: 'wetrust.svg' },
  { filename: 'win.svg', 'optional-1': true },
  { filename: 'sele-ag.svg' },
  { filename: 'genussfestival.svg' },
  { filename: 'integration.svg' },
  { filename: 'lokal-fair.svg' },
  { filename: 'vogt-partner.svg' },
  { filename: 'lis.svg' },

  { filename: 'lasermed.png', png: true },
  { filename: 'naegelelaw.png', png: true },
  { filename: 'hundestil.svg' },
  { filename: 'oroclean.svg' },
]

export function LogoWall() {
  const [logos, setLogos] = useState(logosData)

  useEffect(() => {
    const randomLogos = shuffle(logos)
    setLogos(randomLogos)
  }, [])

  return (
    <Wrapper>
      <Content>
        <Heading>
          <strong>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="heart"
              class="svg-inline--fa fa-heart"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M462.3 62.71c-54.5-46.5-136.1-38.99-186.6 13.27l-19.69 20.61l-19.71-20.61C195.6 33.85 113.3 8.71 49.76 62.71C-13.11 116.2-16.31 212.5 39.81 270.5l193.2 199.7C239.3 476.7 247.8 480 255.9 480c8.25 0 16.33-3.25 22.58-9.751l193.6-199.8C528.5 212.5 525.1 116.2 462.3 62.71zM449.3 248.2l-192.9 199.9L62.76 248.2C24.39 208.7 16.39 133.2 70.51 87.09C125.3 40.21 189.8 74.22 213.3 98.59l42.75 44.13l42.75-44.13c23.13-24 88.13-58 142.8-11.5C495.5 133.1 487.6 208.6 449.3 248.2z"
              ></path>
            </svg>
            -lichen Dank
          </strong>
          <br />
          Über 350 Projekte für 60 Kunden in 15 Jahren.
        </Heading>
        <Logos>
          {logos.map((logo, index) => (
            <LogosItem
              key={index}
              className={logo['optional-1'] && 'optional-1'}
            >
              <Logo key={index}>
                {logo.link ? (
                  <a href={logo.link} target="_blank" rel="noopener noreferrer">
                    <Image
                      src={`/logos/${logo.filename}`}
                      alt={logo.alt}
                      className={logo.png ? 'is-png' : 'is-svg'}
                    />
                  </a>
                ) : (
                  <Image
                    src={`/logos/${logo.filename}`}
                    alt={logo.alt}
                    className={logo.png ? 'is-png' : 'is-svg'}
                  />
                )}
              </Logo>
            </LogosItem>
          ))}
        </Logos>
      </Content>
    </Wrapper>
  )
}
