import styled, { css } from 'styled-components'

const Title = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 84px;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 1rem 0;

  @media (max-width: 860px) {
    font-size: 60px;
  }

  @media (max-width: 480px) {
    font-size: 54px;
  }

  ${props =>
    props.size === 's' &&
    css`
      font-size: 42px;

      @media (max-width: 480px) {
        font-size: 42px;
      }
    `}

  ${props =>
    props.size === 'xs' &&
    css`
      font-size: 36px;

      @media (max-width: 480px) {
        font-size: 32px;
      }
    `}
`

export default Title
