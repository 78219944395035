import React from 'react'
import styled, { css } from 'styled-components'

import Content from './Content'
import Title from './Title'
import Headline from './Headline'

const Wrapper = styled.div``

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  width: 33.333333%;
  padding-right: 100px;
  margin-bottom: 80px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${props =>
    props.profile &&
    css`
      display: flex;
    `}

  @media (max-width: 1280px) {
    width: 50%;
    padding-right: 60px;
  }

  @media (max-width: 860px) {
    width: 100%;
    padding-right: 0;
  }
`

const Intro = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0 0 0.5em 0;
`

const Text = styled.div`
  font-size: 21px;
`

const About = () => (
  <Wrapper>
    <Content>
      <Headline>Studio</Headline>
      <Columns>
        <Column>
          <Title>Warum?</Title>
          <Intro>
            Wir lieben es digital!
            <br /> Sie auch?
          </Intro>
          <Text>
            Die Digitalisierung bringt uns viele neue Möglichkeiten.
            Informationen werden auf Webseiten schneller gefunden,
            Arbeitsprozesse werden mit einer App vereinfacht und auch kleine
            Unternehmen haben auf dem Google Marktplatz gute Chancen mit der
            passenden Online-Strategie. Was wir tun, tun wir gerne. Darum
            vergeht bei uns kein Tag ohne digitale Innovation.
          </Text>
        </Column>
        <Column>
          <Title>Wie?</Title>
          <Intro>Wir verwenden moderne Webtechnologien.</Intro>
          <Text>
            Der Umsetzung sind keine Grenzen gesetzt. Geht nicht – gibt es bei
            uns nicht! Bei unseren Umsetzungen legen wir grossen Wert darauf,
            dass Sie mit Ihrer Website oder Ihrer App wachsen können und dass
            alles weiter&shy;entwickelt werden kann. Usability, Design und
            Sicherheit bringen wir in unseren Projekten zusammen.
          </Text>
        </Column>
        <Column>
          <Title>Was?</Title>
          <Intro>Wir erstellen Webseiten, Apps und digitale Strategien.</Intro>
          <Text>
            Wir programmieren für Sie die neue Firmen-Website. Mit
            aussergewöhnlichen Animationen erwecken wir sie zum Leben. Apps sind
            nicht nur auf Ihrem Mobile. Apps sind auch Web-Applikationen, zum
            Beispiel die digitale Event&shy;verwaltung oder das
            Daten&shy;austausch&shy;portal für Sie und Ihre Kunden. Wir machen
            auch andere digitale Sachen, die zu einem gelungenen Auftritt
            gehören: Newsletter, Online-Marketing und Digital Strategie.
          </Text>
        </Column>
      </Columns>
    </Content>
  </Wrapper>
)

export default About
