import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXing, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import Hightlight from './Highlight'

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
`

const PhotoImg = styled(Img)`
  display: block;
  width: 100%;
  height: auto;
`

const Content = styled.div`
  margin-bottom: -144px;
  height: 144px;
  padding-top: 1rem;
  position: relative;

  @media (max-width: 1280px) {
    height: auto;
    margin-bottom: 0;
  }
`

const Name = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.3;
`

const Title = styled.div`
  margin: 0 0 0.75rem 0;
  opacity: 0.7;
`

const Mail = styled.a`
  font-weight: bold;
  font-size: 21px;
  color: inherit;
`

const Social = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 0;
  font-size: 24px;
`

const SocialItem = styled.div`
  display: inline-block;
  margin-left: 1rem;
`

const SocialLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 24px;
`

const Profile = ({ name, photo, title, email, xing, linkedin, vcard }) => (
  <Wrapper>
    <PhotoImg fluid={photo} />
    <Content>
      <Social>
        <SocialItem>
          <SocialLink
            href={xing}
            title={`${name} auf XING`}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faXing} />
          </SocialLink>
        </SocialItem>
        <SocialItem>
          <SocialLink
            href={linkedin}
            title={`${name} auf LinkedIn`}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </SocialLink>
        </SocialItem>
      </Social>
      <Name>{name}</Name>
      <Title>{title}</Title>
      <Mail href={`mailto:${email}`} title={`${name} ein E-Mail schreiben`}>
        <Hightlight>{email}</Hightlight>
      </Mail>
    </Content>
  </Wrapper>
)

export default Profile
