import React from 'react'
import styled from 'styled-components'

import Content from '../Content'
import Title from '../Title'
import Highlight from '../Highlight'
import ArrowRight from '../Icons/ArrowRight'

const Wrapper = styled.div`
  background: #fff;
  padding: 6rem 0 3rem 0;
  margin-bottom: 6rem;

  @media (min-width: 1024px) {
    padding: 9rem 0 6rem 0;
    margin-bottom: 9rem;
  }
`

const Heading = styled.div`
  max-width: 1110px;
  margin-bottom: 48px;
`

const Columns = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const Column = styled.div`
  @media (max-width: 1023px) {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  @media (min-width: 1024px) {
    width: 50%;
    padding-right: 100px;
  }
`

const Intro = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0 0 0.5em 0;
`

const Text = styled.div`
  font-size: 21px;
`

const Button = styled.a`
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  svg {
    margin-left: 8px;
    height: 20px;
    width: auto;
    position: relative;
    top: 1px;
    transition: transform 0.25s ease;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`

const Meta = styled.div`
  max-width: 500px;
`

const Media = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 1rem;
`

export const Img = styled.img`
  display: block;
  height: auto;
  width: 200vw;

  @media (min-width: 800px) {
    width: 100vw;
  }

  @media (min-width: 1560px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
`

export const NameSecure = () => (
  <Wrapper>
    <Content>
      <Heading>
        <Intro>NameSecure</Intro>
        <Title>Namensfindung und Domain-Prüfung.</Title>
      </Heading>
      <Columns>
        <Column>
          <Text>
            <strong>
              Ergänzen Sie Ihr digitales Projekt mit unserem KI-gestützten
              Service für Namensfindung, Domain-Prüfung und Markenrecherche!
            </strong>
            <br />
            <br />
            Wir erweitern für Sie unsere digitalen Services, um Sie noch
            umfassender zu unterstützen! Wir präsentieren: NameSecure – Ihr
            Rundum-Sorglos-Paket für eine sichere und fundierte Namensfindung.
            Dieser Service wurde entwickelt, um Ihnen nicht nur bei der Auswahl
            des perfekten Namens zu helfen, sondern auch um sicherzustellen,
            dass dieser Name verfügbar ist. Mit Hilfe von KI-Technologie
            recherchieren wir bestehende Marken, Abkürzungen und prüfen
            Domain-Verfügbarkeiten, alles nach Ihren individuellen Vorgaben. Sie
            erhalten bis zu fünf massgeschneiderte Namensvorschläge und starten
            Ihr digitales Projekt mit dem richtigen Namen einfach und schneller!
          </Text>
        </Column>
        <Column>
          <Meta>
            <Text>
              3 Vorschläge: CHF 1500.-
              <br />
              5 Vorschläge: CHF 2000.-
              <br />
              nur Domainvorschlag: CHF 800.-
            </Text>
            <br />
            <Button href="https://namesecure-a45.paperform.co/" target="_blank">
              <Highlight>Jetzt anfragen</Highlight> <ArrowRight />
            </Button>
          </Meta>
        </Column>
      </Columns>
    </Content>
  </Wrapper>
)
