import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Content from './Content'
import Highlight from './Highlight'
import ArrowRight from './Icons/ArrowRight'

const Button = styled(Link)`
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    height: 20px;
    width: auto;
    position: relative;
    top: 1px;
    transition: transform 0.25s ease;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`

const Text = styled.div`
  font-size: 50px;
  display: inline-block;
  font-weight: bold;
  line-height: 1;
`

const Wrapper = styled.div`
  padding: 8rem 0;
  padding-left: 33.333333%;
  display: block;
  color: inherit;
  text-decoration: none;

  @media (max-width: 1024px) {
    padding-left: 0;
  }
`

const CTA = () => (
  <Content>
    <Wrapper>
      <Text>
        Eine Idee besprechen,
        <br />
        oder einfach Hallo sagen.
      </Text>
      <br />
      <br />
      <br />
      <Button to="/kontakt">
        <Highlight>Kontakt aufnehmen</Highlight> <ArrowRight />
      </Button>
    </Wrapper>
  </Content>
)

export default CTA
