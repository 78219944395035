import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-size: 220px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 3rem;
  opacity: 0.05;
  margin-left: -0.05em;

  @media (max-width: 960px) {
    font-size: 22vw;
    margin-bottom: 1rem;
  }
`

const Headline = ({ children }) => (
  <Wrapper role="presentation" aria-hidden="true">
    {children}
  </Wrapper>
)

export default Headline
