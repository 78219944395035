import React from 'react'
import styled from 'styled-components'

import Title from './Title'
import Highlight from './Highlight'
import ArrowRight from './Icons/ArrowRight'

const Wrapper = styled.div`
  width: 100%;
  background: #feef9e;
  display: flex;
`

const Content = styled.div`
  padding: 2rem;
  position: relative;
  align-self: flex-end;
`

const Text = styled.div`
  margin-bottom: 2rem;
`

// TODO: Button hier und in CTA zusammenführen.
const Button = styled.a`
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    height: 20px;
    width: auto;
    position: relative;
    top: 1px;
    transition: transform 0.25s ease;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`

const ProfileNerd = ({ title, text }) => (
  <Wrapper>
    <Content>
      <Title size="s">{title}</Title>
      <Text>{text}</Text>
      <Button href="mailto:hallo@a45.li" title="Jetzt bewerben">
        <Highlight light>Jetzt bewerben</Highlight> <ArrowRight />
      </Button>
    </Content>
  </Wrapper>
)

export default ProfileNerd
