import React from 'react'
import styled, { css } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import Content from './Content'
import Profile from './Profile'
import ProfileNerd from './ProfileNerd'

const Wrapper = styled.div`
  margin: 6rem 0 12rem 0;

  @media (max-width: 1280px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 680px) {
    margin-bottom: 0rem;
  }
`

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -80px;
`

const Column = styled.div`
  width: 33.333333%;
  padding-right: 80px;
  padding-top: 80px;

  &:last-of-type {
    padding-right: 0;
  }

  ${props =>
    props.asProfile &&
    css`
      display: flex;
    `}

  @media (max-width: 1280px) {
    width: 50%;
    padding-right: 60px;
  }

  @media (max-width: 860px) {
    width: 100%;
    padding-right: 0;
  }
`

const Code = styled.span`
  font-family: monospace;
`

const Team = () => (
  <Wrapper>
    <Content>
      <StaticQuery
        query={graphql`
          query {
            bernhardImage: file(
              relativePath: { eq: "180808_A45_Foto_Bernhard.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            lauraImage: file(
              relativePath: { eq: "180808_A45_Foto_Laura.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <Columns>
            <Column asProfile>
              <Profile
                photo={data.bernhardImage.childImageSharp.fluid}
                name="Bernhard Wanger"
                title="Inhaber"
                email="bernhard@a45.li"
                xing="https://www.xing.com/profile/Bernhard_Wanger/cv"
                linkedin="https://www.linkedin.com/in/bernhard-wanger-b943b416b/"
                vcard="https://www.google.com"
              />
            </Column>
            <Column asProfile>
              <Profile
                photo={data.lauraImage.childImageSharp.fluid}
                name="Laura Wanger"
                title="Inhaberin"
                email="laura@a45.li"
                xing="https://www.xing.com/profile/Laura_Wanger2/cv"
                linkedin="https://www.linkedin.com/in/laura-wanger-168706164/"
                vcard="https://www.google.com"
              />
            </Column>
            <Column asProfile>
              <ProfileNerd
                title="Frontend-Entwickler/in"
                text={
                  <>
                    <Code>r/ProgrammerHumor</Code> findest du lustig? Und die
                    Links in React Weekly kennst du meistens schon?
                  </>
                }
              />
            </Column>
          </Columns>
        )}
      />
    </Content>
  </Wrapper>
)

export default Team
