import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Content from './Content'
// import Title from './Title'

const Wrapper = styled.div`
  margin-bottom: 8rem;

  @media (max-width: 680px) {
    margin-bottom: 6rem;
  }
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-direction: row;
  margin-left: -48px;
  margin-right: -48px;

  @media (min-width: 1280px) {
    margin-left: -96px;
    margin-right: -96px;
  }

  @media (min-width: 1680px) {
    margin-left: -128px;
    margin-right: -128px;
  }
`

const Item = styled.div`
  padding: 0 48px;
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 48px;

  @media (min-width: 1280px) {
    flex-basis: 50%;
    max-width: 50%;

    &:nth-child(even) {
      padding-top: 96px;
    }
  }
`

const Project = styled.div``

const MediaImg = styled(Img)`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
`

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const Description = styled.div`
  opacity: 0.7;
  font-style: italic;
  font-size: 80%;
`

const Title = styled.div`
  margin: 0;
  padding: 0;
`

const projects = [
  {
    name: 'FBP',
    title: 'Website',
    partner: 'Walser Grafik',
    url: 'https://www.fbp.li/',
  },
  {
    name: 'VAD',
    title: 'Konzept, Plattform, digital LIHGA',
    partner: 'Leone Ming',
    url: 'https://digital.lihga.li/',
  },
  {
    name: 'GEN',
    title: 'Website',
    partner: 'VP Bank',
    url: 'https://www.genussfestival.li/',
  },
  {
    name: 'NEX',
    title: 'Plattform, digital Berufs- und Bildungstage',
    partner: 'Leone Ming',
    url: 'https://digital.next-step.li/',
  },
  {
    name: 'SEL',
    title: 'Website',
    partner: 'Mathias Marxer',
    url: 'https://www.sele-ag.li/',
  },
  {
    name: 'LAP',
    title: 'Beratung, Online-Bestellung, Website',
    partner: 'Leone Ming',
    url: 'https://www.lapreva.com/',
  },
  {
    name: 'SLL',
    title: 'Website',
    partner: 'Michèle Steffen Grafik',
    url: 'https://www.lebenswertesliechtenstein.li/',
  },
  {
    name: 'ITW',
    title: 'Wohnungsverwaltung, Website',
    partner: 'Büro für Gebrauchsgraphik',
    url: 'https://www.wida.li/',
  },
  {
    name: 'TAK',
    title: 'Beratung, Veranstaltungsverwaltung, Website',
    partner: 'Co-Agency',
    url: 'https://www.tak.li/',
  },
  {
    name: 'GAP',
    title: 'Publikationsverwaltung, Website',
    partner: 'Leone Ming',
    url: 'https://gasserpartner.com/',
  },
  {
    name: 'VOP',
    title: 'Website',
    partner: 'Büro für Gebrauchsgraphik',
    url: 'https://www.vogtpartner.li/',
  },
  {
    name: 'SOS',
    title: 'Spendenschnittstelle, Website',
    partner: 'Leone Ming',
    url: 'https://www.sos-kinderdorf.li/',
  },
  {
    name: 'LIF',
    title: 'Website',
    partner: 'Leone Ming',
    url: 'https://fl1.life/',
  },
  {
    name: 'VAD',
    title: 'Website',
    partner: 'Büro für Gebrauchsgraphik',
    url: 'https://www.lokalundfair.li/',
  },
  {
    name: 'LIS',
    title: 'Website',
    partner: 'Leone Ming',
    url: 'https://www.lis.li/',
  },
  {
    name: 'FRA',
    title: 'Website',
    partner: 'Brandwork',
    url: 'https://www.franzhasler.li/',
  },
  {
    name: 'LEM',
    title: 'Website',
    partner: 'Leone Ming',
    url: 'https://www.leoneming.com/',
  },
  {
    name: 'HEL',
    title: 'Erweiterungen für Online-Shop',
    url: 'https://www.herzogloibner.com/',
  },
  {
    name: 'MAQ',
    title: 'Datenaustausch (Software), Website',
    partner: 'Brandwork',
    url: 'https://www.maq.ch/',
  },
  {
    name: 'BFF',
    title: 'Website',
    partner: 'ruby',
    url: 'https://www.businesstag.li/',
  },
  // {
  //   name: 'GEB',
  //   title: 'Website',
  //   partner: 'KontaktKomponisten',
  //   url: 'https://www.gebr-beck.li/',
  // },
  // {
  //   name: 'HUN',
  //   title: 'Online-Shop',
  //   url: 'https://www.hundestil.ch/',
  // },
]

const Projects = () => (
  <Wrapper>
    <StaticQuery
      query={graphql`
        query {
          allFile(
            filter: {
              extension: { regex: "/(jpg)|(jpeg)|(png)/" }
              relativeDirectory: { eq: "projekte" }
            }
            sort: { fields: name, order: ASC }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    aspectRatio
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Content>
            <Items>
              {data.allFile.edges.map(({ node }, index) => {
                return (
                  <Item key={index}>
                    <Project>
                      <a
                        href={projects[index].url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MediaImg fluid={node.childImageSharp.fluid} />
                      </a>
                      <Info>
                        <Title>{projects[index].title}</Title>
                        {projects[index].partner && (
                          <Description>
                            mit {projects[index].partner}
                          </Description>
                        )}
                      </Info>
                    </Project>
                  </Item>
                )
              })}
            </Items>
          </Content>
        )
      }}
    />
  </Wrapper>
)

export default Projects
